import React, { useState } from 'react';
import Img from 'gatsby-image';
import classNames from 'classnames';
import t, { _t } from '../../utils/translate';
import useInterval from '../../hooks/useInterval';
import styles from './ImageCarousel.module.scss';

export default function ImageCarousel({ images, carouselTitle }) {
  let [active, setActive] = useState(0);
  const [isRunning, setIsRunning] = useState(true);
  const [delay] = useState(7000);

  useInterval(
    () => {
      nextSlide();
    },
    isRunning ? delay : null
  );

  const nextSlide = () => {
    if (active === images.length - 1) {
      setActive(0);
    } else {
      setActive(active + 1);
    }
  };

  const prevSlide = () => {
    if (active === 0) {
      setActive(images.length - 1);
    } else {
      setActive(active - 1);
    }
  };

  return (
    <div
      role="figure"
      className={styles.carouselOuter}
      onMouseEnter={() => setIsRunning(false)}
      onMouseLeave={() => setIsRunning(true)}
    >
      {carouselTitle && (
        <h3 className={classNames(styles.carouselTitle, 'headline-40')}>
          {_t(carouselTitle)}
        </h3>
      )}

      {images.map((image, index) => {
        if (index === active) {
          return (
            <span key={index}>
              <Img
                className={styles.image}
                fluid={image.asset.fluid}
                alt={image.alt}
              />
            </span>
          );
        } else {
          return null;
        }
      })}
      {images.length > 1 && (
        <>
          <button
            aria-hidden={true}
            aria-label={t('prevSlide')}
            tabIndex="-1"
            onClick={() => prevSlide()}
            className={classNames(styles.imageButton, styles.imageButtonLeft)}
          />
          <button
            aria-hidden={true}
            aria-label={t('nextSlide')}
            tabIndex="-1"
            onClick={() => nextSlide()}
            className={classNames(styles.imageButton, styles.imageButtonRight)}
          />

          <nav className={styles.navOuter}>
            {images.map((images, index) => {
              return (
                <button
                  key={index}
                  className={active === index ? styles.active : undefined}
                  onClick={() => setActive(index)}
                  onFocus={() => {
                    setIsRunning(false);
                  }}
                  onBlur={() => {
                    setIsRunning(true);
                  }}
                  aria-label={`${t('imageSelect')} ${index + 1}`}
                />
              );
            })}
          </nav>
        </>
      )}
    </div>
  );
}
