import React from 'react';
import Img from 'gatsby-image';
import classNames from 'classnames';
import styles from './SubpageBlocks.module.scss';
import t from '../../utils/translate';
import { fluidImagePropHelper } from '../../utils/imagePropHelper';
import ImageCarousel from '../ImageCarousel/ImageCarousel';
import Text from '../Body/Blocks/Text/Text';
import InfoBlock from '../Blocks/InfoBlock/InfoBlock';
import Accordion from '../Body/Blocks/Accordion/Accordion';
import VideoEmbed from '../VideoEmbed/VideoEmbed';

export default function SubpageBlocks({ contents, location }) {
  // console.log('content', contents);

  return (
    <div className={styles.subpageOuter}>
      {contents.map(content => {
        switch (content._type) {
          case 'imageCarousel':
            content.images.forEach(
              image =>
                (image.asset.fluid = fluidImagePropHelper(
                  image.asset.id,
                  1640,
                  780
                ))
            );

            return (
              <div key={content._key} className={styles.subpageBlock}>
                <ImageCarousel
                  images={content.images}
                  carouselTitle={content.carouselTitle}
                />
              </div>
            );

          case 'articleImageAndText':
            content.media.forEach(item => {
              if (item._type === 'mediaImage') {
                item.asset.fluid = fluidImagePropHelper(
                  item.asset.id,
                  800,
                  530
                );
              }
            });

            return (
              <div
                key={content._key}
                className={classNames(
                  styles.subpageBlock,
                  styles.textAndImage,
                  content.reverse ? styles.reverse : null
                )}
              >
                <div className={styles.textOuter}>
                  <Text content={content.content} />
                </div>
                <div className={styles.mediaOuter}>
                  {content.media[0]._type === 'mediaImage' && (
                    <Img
                      fluid={content.media[0].asset.fluid}
                      alt={content.media[0].alt}
                    />
                  )}

                  {content.media[0]._type === 'infoBlocks' && (
                    <div className={styles.infoBlockOuter}>
                      <InfoBlock
                        headline={content.media[0].headline}
                        content={content.media[0].content}
                        textField={content.media[0].textField}
                        location={location}
                        dark={false}
                      />
                    </div>
                  )}

                  {content.media[0]._type === 'articleVideoEmbed' && (
                    <>
                      {content.media[0].videoUpload ? (
                        <video
                          width="100%"
                          autoPlay
                          loop
                          muted
                          controls={false}
                          src={content.media[0].videoUpload.asset.url}
                          poster={
                            content.media[0].videoUpload.videoPoster
                              ? content.media[0].videoUpload.videoPoster.asset
                                  .url
                              : null
                          }
                        >
                          {t('videoAlert')}
                        </video>
                      ) : (
                        <VideoEmbed video={content.media[0].id} />
                      )}
                    </>
                  )}
                </div>
              </div>
            );

          case 'subArticleText':
            return (
              <div
                key={content._key}
                className={classNames(
                  styles.subpageBlock,
                  styles.singleText,
                  content.reverse ? styles.reverse : null
                )}
              >
                <Text content={content.content} />
              </div>
            );

          case 'articleVideoEmbed':
            return (
              <div
                key={content._key}
                className={classNames(styles.subpageBlock, styles.videoOuter)}
              >
                {content.videoUpload ? (
                  <video
                    width="100%"
                    autoPlay
                    loop
                    muted
                    controls={false}
                    src={content.videoUpload.asset.url}
                    poster={
                      content.videoUpload.videoPoster
                        ? content.videoUpload.videoPoster.asset.url
                        : null
                    }
                  >
                    {t('videoAlert')}
                  </video>
                ) : (
                  <VideoEmbed video={content.id} />
                )}
              </div>
            );

          case 'teaserWithImages':
            return (
              <div
                key={content._key}
                className={classNames(
                  styles.subpageBlock,
                  styles.teaserWithImage
                )}
              >
                {content.teaserWithImageContent.map(item => {
                  item.media[0].asset.fluid = fluidImagePropHelper(
                    item.media[0].asset.id,
                    800,
                    item.imageOrientation ? 945 : 530
                  );
                  return (
                    <div
                      key={item._key}
                      className={styles.teaserWithImageBlock}
                    >
                      <div className={styles.teaserImageOuter}>
                        <Img
                          fluid={item.media[0].asset.fluid}
                          alt={item.media[0].alt}
                        />
                      </div>
                      <Text content={item.text} />
                    </div>
                  );
                })}
              </div>
            );

          case 'articleAccordion':
            return (
              <div
                key={content._key}
                className={classNames(
                  styles.subpageBlock,
                  styles.accordionOuter
                )}
              >
                <Accordion
                  headline={content.headline}
                  content={content.content}
                  dark={false}
                />
              </div>
            );

          default:
            console.log('unknown type', content._type);
            return null;
        }
      })}
    </div>
  );
}
