import React, { useRef, useState } from 'react';
import BlockContent from '@sanity/block-content-to-react';
import classNames from 'classnames';
import { _t } from '../../../../utils/translate';
import styles from './Accordion.module.scss';
import { serializers } from '../../../LinkAnnotation/LinkAnnotation';

export default function Accordion({ headline, content, dark }) {
  const [open, setOpen] = useState(false);
  const [height, setHeight] = useState(0);

  const block = useRef(null);

  const toggleAccordion = () => {
    open ? setHeight(0) : setHeight(block.current.clientHeight);
    setOpen(!open);
  };

  return (
    <div
      className={classNames(styles.accordionOuter, dark ? styles.dark : null)}
    >
      <button
        title="Åpne accordion"
        data-open={open}
        className={styles.headline}
        onClick={() => toggleAccordion()}
      >
        {_t(headline)}
      </button>
      <div style={{ height: `${height}px` }} className={styles.contentOuter}>
        <div
          ref={block}
          className={classNames(styles.contentInner, 'body-text')}
        >
          <BlockContent blocks={_t(content)} serializers={serializers} />
        </div>
      </div>
    </div>
  );
}
