import React from 'react';
import t from '../../utils/translate';
import styles from './Breadcrumbs.module.scss';
import LocaleLink from '../LocaleLink/LocaleLink';

export default function Breadcrumbs({ title, location, shortTitle }) {
  // console.log('shortTitle', shortTitle);
  let locations = location.pathname.split('/');

  let crumbs = [];

  locations.forEach((location, index) => {
    let crumbObject = {
      url: location,
      label: location === 'besok' ? 'besøk' : location,
    };

    if (index === locations.length - 1 && crumbObject.url === '') {
      // Escape if there's a trailing slash at the end of the URL
      return;
    }

    crumbs.push(crumbObject);
  });

  return (
    <div className={styles.breadcrumbsOuter}>
      {crumbs.map((crumb, index) => {
        if (index === crumbs.length - 1) {
          return (
            <p key={index} className={styles.crumbLabel}>
              {title}
            </p>
          );
        } else if (index === 2 && crumb.label === 'undervisning') {
          return (
            <p key={index} className={styles.crumbLabel}>
              {crumb.label}
              <span className={styles.seperator}>/</span>
            </p>
          );
        } else {
          return (
            <p key={index} className={styles.crumbLabel}>
              <LocaleLink to={`/${crumb.url}`}>
                {index === 0 ? t('frontpage') : crumb.label}
              </LocaleLink>{' '}
              <span className={styles.seperator}>/</span>
            </p>
          );
        }
      })}
    </div>
  );
}
