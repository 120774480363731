import React from 'react';
import styles from './VideoEmbed.module.scss';

export default function VideoEmbed({ video }) {
  const embedURL = `https://www.youtube-nocookie.com/embed/${video}`;

  return (
    <div className={styles.embedOuter}>
      <iframe
        title="Embed video"
        className={styles.iframe}
        width="100%"
        height="100%"
        src={embedURL}
        frameBorder="0"
        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
      ></iframe>
    </div>
  );
}
