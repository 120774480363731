import React from 'react';
import classNames from 'classnames';
import { useSpring, animated, config } from 'react-spring';
import { _t } from '../../utils/translate';
import styles from './SubpageHeader.module.scss';
import Breadcrumbs from '../Breadcrumbs/Breadcrumbs';
import ImageOrVideo from '../ImageOrVideo/ImageOrVideo';

export default function SubpageHeader({
  title,
  headline,
  intro,
  eventDate,
  media = null,
  location,
  shortTitle,
  hideBreadcrumbs,
}) {
  const headProps = useSpring({
    opacity: 1,
    transform: 'translate3d(0px, 0px, 0px)',
    from: { opacity: 0, transform: 'translate3d(0px, 50px, 0px)' },
    config: config.slow,
  });
  const subProps = useSpring({
    opacity: 1,
    transform: 'translate3d(0px, 0px, 0px)',
    from: { opacity: 0, transform: 'translate3d(0px, 50px, 0px)' },
    config: config.slow,
    delay: 300,
  });

  return (
    <div className={styles.pageHeaderOuter}>
      <div className={styles.pageHeaderInner}>
        {media && (
          <div className={styles.assetOuter}>
            {media.map((media, index) => {
              return (
                <ImageOrVideo key={index} subpage={true} content={media} />
              );
            })}
          </div>
        )}

        <div className={styles.textOuter}>
          {hideBreadcrumbs ? null : (
            <Breadcrumbs
              title={_t(headline)}
              location={location}
              shortTitle={shortTitle}
            />
          )}
          <animated.h1
            style={headProps}
            className={classNames(styles.headline, 'headline-80')}
          >
            {_t(headline)}
          </animated.h1>
          {eventDate
            ? eventDate.start && (
                <p className={styles.date}>
                  {eventDate.start} {'- ' + eventDate.end}
                </p>
              )
            : null}
          <animated.p
            style={subProps}
            className={classNames(styles.ingress, 'ingress')}
          >
            {_t(intro)}
          </animated.p>
        </div>
      </div>
    </div>
  );
}
