import React from 'react';

import t, { _t } from '../utils/translate';
import Layout from '../components/Layout/Layout';
import SubpageHeader from '../components/SubpageHeader/SubpageHeader';
import SubpageBlocks from '../components/SubpageBlocks/SubpageBlocks';
import SubpageLoader from '../components/SubpageLoader/SubpageLoader';
import SEO from '../components/seo';
import useSetLocale from '../hooks/useSetLocale';

const LearningResources = ({ location, pageContext }) => {
  useSetLocale();
  const {
    intro,
    headline,
    imageOrVideo,
    body,
    otherLearningResources,
    shortTitle,
  } = pageContext.data;

  return (
    <Layout location={location}>
      <SEO
        title={`${_t(headline)} | SeeSalmon`}
        // description={seo && seo.description ? seo.description : null}
        // image={seo && seo.image ? seo.image.asset.fixed.src : null}
      />
      <SubpageHeader
        headline={headline}
        intro={intro}
        media={imageOrVideo}
        location={location}
        shortTitle={shortTitle}
      />
      <div className="page-padding">
        {body && <SubpageBlocks location={location} contents={body} />}
      </div>
      {otherLearningResources.length > 0 && (
        <SubpageLoader
          loadFrom="learning"
          dark={false}
          headline={t('linkedLearningHeadline')}
          edges={otherLearningResources}
        />
      )}
    </Layout>
  );
};

export default LearningResources;
