import React from 'react';
import BlockContent from '@sanity/block-content-to-react';
import classNames from 'classnames';
import { _t } from '../../../../utils/translate';
import styles from './Text.module.scss';
import { serializers } from '../../../LinkAnnotation/LinkAnnotation';

export default function Text({ content, dark }) {
  return (
    <div
      className={classNames(
        'body-text',
        styles.textOuter,
        dark ? styles.dark : null
      )}
    >
      {content && (
        <BlockContent blocks={_t(content)} serializers={serializers} />
      )}
    </div>
  );
}
